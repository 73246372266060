.s-typingText {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 40vw;
	min-height: 550px;
	padding-top: rem(160);
	padding-bottom: rem(170);
	position: relative;
	margin-bottom: rem(164);

	background-color: $color-3;

	@media (max-width: $lg) {
		margin-bottom: rem(96);

		text-align: center;

		padding-block: rem(60);
	}

	@media (max-width: $md) {
		height: initial;
		min-height: rem(400);
	}

	&__wrapper {
		width: 100%;

		position: absolute;

		font-family: $font-secondary;
		font-size: rem(72);

		padding-inline: rem(125);

		@media (min-width: $lg) {
			top: rem(58);
			line-height: 4.5rem;
		}

		@media (max-width: $lg) {
			font-size: rem(54);
			line-height: normal;

			padding-inline: rem(40);
		}

		@media (max-width: $md) {
			font-size: rem(40);
			line-height: normal;

			padding-inline: rem(20);
		}
	}

	&__cloud {
		@media (max-width: $lg) {
			margin-bottom: 0;
		}
	}

	&__text {
		margin-top: rem(-80);
		margin-bottom: rem(24);
	}

	&--vr {
		height: unset;
		min-height: rem(370);
		padding: rem(70) 0;

		position: relative;

		.s-typingText__wrapper {
			margin-top: 0;

			@media (min-width: $md) {
				top: 3rem;
			}
		}

		@media (min-width: $md) {
			min-height: rem(470);
		}

		&.s-typingText--product-visualisation {
			min-height: rem(420);

			margin-bottom: 0;

			.s-typingText__wrapper {
				margin-left: 0;

				@media (min-width: $md) {
					top: 3rem;

					padding-inline: rem(125) 20%;
				}
			}

			.s-typingText-bg {
				width: rem(344);
				height: rem(702);

				top: rem(-74);
				right: rem(-90);
				bottom: 0;
			}

			@media (min-width: $md) {
				min-height: rem(528);

				.s-typingText-bg {
					width: rem(484);
					height: rem(702);

					top: rem(-170);
					right: rem(-10);
					bottom: 0;
				}
			}
		}

		&.s-typingText--health {
			min-height: rem(370);

			overflow: hidden;

			@media (min-width: $md) {
				.s-typingText__wrapper {
					margin-left: 0;
					top: 3rem;
					padding-inline: rem(125) 25%;
				}
			}

			@media (min-width: $xl) {
				min-height: rem(528);
			}

			.s-typingText-bg {
				width: rem(500);
				height: auto;

				top: auto;
				right: rem(-370);
				bottom: 0;

				opacity: 0.6;

				@media (min-width: $md) {
					width: rem(628);

					opacity: 1;
				}

				@media (min-width: $xl) {
					width: rem(700);
					height: 100%;

					top: 0;
					right: rem(-90);
					bottom: 0;
				}
			}
		}

		&.s-typingText--events {
			min-height: rem(528);

			.s-typingText__wrapper {
				margin-left: 0;
				top: 3rem;
				padding-inline: rem(125) 25%;
			}

			.s-typingText-bg {
				width: rem(252);
				height: 100%;

				top: rem(-72);
				right: 0;
				bottom: 0;

				@media (min-width: $sm) {
					width: rem(380);
				}

				@media (min-width: $md) {
					width: rem(430);
				}

				@media (min-width: $xl) {
					width: rem(550);

					top: rem(-120);
				}

				@media (min-width: $xxl) {
					width: rem(668);

					top: rem(-120);
				}
			}
		}

		.s-typingText-bg {
			width: rem(258);

			position: absolute;
			top: rem(-80);
			right: rem(-50);
			bottom: auto;

			@media (min-width: $md) {
				width: rem(1170);
				height: rem(667);

				top: rem(-120);
				right: rem(-631);
			}
			@media (min-width: $lg) {
				width: rem(1000);
			}

			@media (min-width: $xxl) {
				width: rem(1170);
			}
		}
	}

	&--ar {
		position: relative;
		min-height: 450px;
		margin-bottom: rem(75);
	}

	&--ar &__wrapper {
		line-height: normal;

		@media (min-width: $lg) {
			line-height: rem(72);
		}
	}

	&--ar &__cloud {
		margin-bottom: 0;
	}

	&--web3 {
		margin-top: rem(110);
		margin-bottom: rem(160);

		@media (max-width: $md) {
			margin-bottom: rem(75);
		}
	}

	&--ai {
		min-height: rem(400);
		padding-bottom: rem(92);

		margin-bottom: rem(0);

		@media (min-height: $lg) {
			min-height: rem(727);

			margin-bottom: rem(50);
		}
	}

	&__services {
		position: relative;
	}

  .extra-text {
	font-size: rem(26);
	font-style: normal;
	font-weight: 200;
	line-height: 160%;
	font-family: $font-base;

	@media (max-width: $md) {
	  font-size: rem(20);
	}
  }

  &--web-analytics,
  &--paid-media,
  &--seo {
	height: 30dvh;

	@media (max-width: $md) {
		height: 65dvh;
	}
	@media (max-width: $sm) {
	  height: 75dvh;
	}

	.s-typingText__wrapper {
	  padding-inline: 1.875rem;
	  margin-bottom: 2rem;

	  &--flex {
		display: flex;
		justify-content: space-between;
		flex-direction: row;

		@media (max-width: $lg) {
		  flex-direction: column;
		  align-items: center;
		}
	  }

	  &__text-wrapper {
		width: 62%;
		@media (max-width: $lg) {
		  width: 100%;
		}
	  }

	  &__image-wrapper {
		width: 32%;
		@media (max-width: $lg) {
		  width: 100%;
		  margin-top: rem(24);
		}

		img {
		  @media (max-width: $lg) {
			width: 50%;
		  }
		  @media (max-width: $sm) {
			width: 70%;
		  }
		  @media (max-width: $xs) {
			width: 90%;
		  }
		}
	  }
	}
  }
}

.typed-cursor {
	display: none;
}
