// Hero Section
.s-hero__sub {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: rem(188);
  height: 100vh;
  min-height: 900px;
  overflow: hidden;
  position: relative;

  &--height-vh {
    min-height: 500px;
  }

  &--mobile-app {
    overflow: visible;
    align-items: flex-start;
    margin-bottom: rem(388);
    min-height: rem(792);

    @media (max-width: 1300px) {
      margin-bottom: rem(650);
    }

    @media (max-width: $lg) {
      min-height: initial;
      height: auto;
      padding-bottom: rem(96) !important;
    }

    .s-hero__sub {
      &__wrapper {
        margin-top: rem(232);
        padding-right: rem($container-padding);

        @media (max-width: 1300px) {
          flex-direction: column;
        }

        @media (max-width: $lg) {
          margin-top: rem(150);
        }
      }

      &__bg {
        overflow: hidden;
      }
    }

    .s-hero__sub__heading {
      width: auto !important;
      flex: 0 1 50%;

      @media (max-width: 1300px) {
        margin-left: 78px;
      }

      @media (max-width: $lg) {
        margin: 0;
      }

      @media (max-width: $md) {
        width: 100% !important;
      }

      &::before {
        @media (max-width: 800px) {
          left: rem(-70);
        }
      }
    }
  }

  @media (max-width: $lg) {
    padding-left: 0;
    display: flex;
    justify-content: center;
    align-self: center;
    padding-bottom: rem(150);
    margin-bottom: rem(96);
  }

  @media (max-width: $md) {}

  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: opacityUp 1.5s;
    animation-fill-mode: forwards;
    top: 0;
    left: 0;

    &--texture {
      width: 100%;
      height: 100%;
      mix-blend-mode: overlay;
      opacity: 0.2;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &--1,
    &--2,
    &--3,
    &--4 {
      position: absolute;
    }

    &--1 {
      top: -16px;
      left: -916px;
      width: 2800px;
      animation: 30s ease-in 10s infinite gradientTransform;
    }

    &--2 {
      top: -1015px;
      right: -1000px;
      animation: 30s ease-in 10s infinite gradientTransform2;
    }

    &--3 {
      top: -94px;
      right: -790px;
    }

    &--4 {
      top: 102px;
      right: -456px;
      width: 100%;
    }
  }

  &__wrapper {
    display: flex;
    position: relative;
    z-index: 5;
    gap: 20px;

    &2 {
      column-gap: rem(100);

      .s-hero__sub__heading {
        width: 50%;

        @media (max-width: $lg) {
          width: 100%;
          gap: 50px;
        }
      }
    }

    &3 {
      column-gap: 16%;
    }

    padding-right: 0;

    @media (max-width: $lg) {
      flex-direction: column;
      padding: 0;
      justify-content: center;
      align-items: center;
      padding-inline: rem(24);
    }
  }

  &--chatbots {
    padding-top: 5;
    min-height: 1100px;

    @media (max-width: $lg) {
      padding-top: 0;
    }
  }

  &__heading {
    width: 55%;
    position: relative;
    word-wrap: break-word;

    &--48 {
      width: 48%;
    }

    &::before {
      content: "";
      background-image: url("../img/arrow.svg");
      background-size: cover;
      background-position: center;
      position: absolute;
      width: 64px;
      height: 64px;
      top: 40px;
      left: -80px;
    }

    @media (max-width: 1330px) {
      width: 60%;
      margin-left: rem(50);
    }

    @media (max-width: 1100px) {}

    @media (max-width: $lg) {
      margin-left: 0;
      width: 100%;
      margin-top: 150px;

      &::before {
        left: -70px;
        top: 35px;
        width: 50px;
        height: 50px;
      }
    }

    @media (max-width: 800px) {
      &::before {
        left: -50px;
      }
    }

    @media (max-width: $md) {
      &::before {
        display: none;
      }
    }

    h1 {
      margin-top: rem(8);
      margin-bottom: rem(8);
      font-size: rem(72);
      line-height: rem(87);

      @media (max-width: $lg) {
        font-size: rem(48);
        line-height: normal;
      }
    }

    p {
      margin-bottom: rem(32);

      &+a {
        margin-top: 0;
      }
    }

    .mw--570+a {
      margin-top: 0;
    }

    a {
      margin-top: rem(15);
      display: inline-block;
    }
  }

  &__imgs {
    display: flex;
    margin-top: rem(-20);

    @media (max-width: 1330px) {
      margin-top: rem(20);
      width: 40%;
    }

    @media (max-width: $lg) {
      display: none;
    }

    &__img {
      &--1 {
        margin-right: rem(20);
      }

      &--2 {
        margin-top: rem(40);
      }
    }

    &--52 {
      &.animation-imgs {
        width: 52%;

        @media screen and (max-width: 991px) {
          width: 100%;
        }
      }
    }
  }

  // Mobile App

  &__images {
    position: relative;
    display: flex;
    flex: 1;

    @media (max-width: 1300px) {
      margin-left: auto;
      padding-right: rem(620);
      margin-top: rem(50);
    }

    @media (max-width: $lg) {
      padding-right: 0;
      display: grid;
      grid-template-columns: repeat(2, auto);
      gap: rem(24);
      margin: rem(35) auto 0;
      width: 60%;
    }

    @media (max-width: $md) {
      width: 100%;
    }

    &-image {
      position: absolute;
      transition: opacity 2s, transform 2s !important;

      @media (max-width: $lg) {
        position: relative;
        top: auto !important;
        left: auto !important;
      }

      img {
        min-width: rem(224);
        transform: rotate(32deg);
        filter: drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.25));
        display: block;

        @media (max-width: $lg) {
          min-width: initial;
          width: 100%;
          transform: initial;
          max-width: rem(225);
        }
      }

      &.is-visible {
        transform: initial !important;
      }

      &-1 {
        top: rem(-55);
        left: rem(-8);
        z-index: 1;

        transform: translateX(400px) !important;

        @media (max-width: $lg) {
          transform: translateX(100) !important;
        }
      }

      &-2 {
        top: rem(-85);
        left: rem(326);
        transform: translateX(240px) !important;

        @media (max-width: $lg) {
          transform: translateX(100) !important;
        }
      }

      &-3 {
        top: rem(345);
        left: rem(56);
        transform: translateX(300px) !important;

        @media (max-width: $lg) {
          transform: translateX(100) !important;
        }
      }

      &-4 {
        top: rem(280);
        left: rem(402);
        transform: translateX(200px) !important;

        @media (max-width: $lg) {
          transform: translateX(100) !important;
        }
      }
    }
  }
}

.s-hero__mad {
  margin-bottom: 0;
}

.animation-imgs {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: $lg) {
    width: 84%;
  }

  @media (max-width: $md) {
    display: none;
  }

  &-hero {
    position: relative;
    transform: translateX(200px);
    opacity: 0;
    animation: position 2s;
    animation-fill-mode: forwards;
  }

  &-el {
    position: absolute;
    opacity: 0;
    animation: position 2s;
    animation-fill-mode: forwards;

    &-1 {
      left: 70%;
      top: 10%;
      transform: translateX(400px);
    }

    &-2 {
      left: 58%;
      top: 20%;
      z-index: -1;
      transform: translateX(400px);
    }

    &-3 {
      left: 60%;
      top: 60%;
      transform: translateX(400px);
    }

    &-4 {
      left: 15%;
      top: 30%;
      z-index: -1;
      transform: translateX(20px);
    }

    &-5 {
      left: 24%;
      top: 20%;
      transform: translateX(50px) translateY(-50px);
    }
  }
}

.animation2-imgs {
  .animation-imgs-el {
    &-3 {
      left: 65%;
      top: 70%;
    }

    &-4 {
      z-index: 1;
      top: 55%;
      left: 15%;
    }

    &-5 {
      left: 0%;
      top: 20%;
    }
  }
}

//healthcare
.animation3-imgs {
  .animation-imgs-el {
    &-1 {
      top: 30%;
      left: 60%;
    }

    &-2 {
      z-index: 1;
      top: 55%;
    }

    &-4 {
      top: 65%;
      left: 16%;
    }

    &-5 {
      z-index: -1;
      left: 13%;
      top: 19%;
    }
  }
}

//mobile app dev
.animation4-imgs {
  .animation-imgs-hero {
    border-radius: 25px;
    position: relative;
    // margin-bottom: rem(-60);

    &-frame {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      animation: screenScroll2 10s infinite;

      &-suzuki {
        animation: ScreenScrollSuzuki1 20s infinite;

        &--2 {
          animation: ScreenScrollSuzuki2 16s infinite;
        }
      }

      &--d-agency {
        animation: screenScroll5 7s infinite;
        animation-delay: 2s;
        transform: translateY(-10%);
      }
    }

    &-phone {
      position: relative;
      border-radius: 25px;
      overflow: hidden;
      will-change: transform;
    }

    &-bar-up {
      position: absolute;
      top: 0;
      left: 0;
      width: 101%;
      z-index: 5;
    }

    &-bar-down {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 5;
    }

    .suzuki__imgs-slide2 {
      top: -11px;
      left: 0;
      animation: slideOpacity1 20s infinite;
      transform: translateX(0);
    }
  }

  .animation-imgs-el {
    &-1 {
      top: 21%;
      left: 63%;
      z-index: -1;
      transform: translateX(350px);
    }

    &-2 {
      z-index: 1;
      top: 47%;
      left: 11%;
      transform: translateX(150px);
    }

    &-3 {
      top: 16%;
      left: 80%;
      transform: translateX(50px) translateY(-60px);
    }

    &-4 {
      top: 72%;
      left: 26%;
      transform: translateX(50px) translateY(-30px);
    }

    &-5 {
      top: 41%;
      left: 25%;
      transform: translateX(50px) translateY(-50px);
    }

    &-6 {
      top: 44%;
      left: 92%;
      transform: translateX(50px) translateY(-30px);
    }

    &-7 {
      top: 61%;
      left: 11%;
      z-index: 1;
      transform: translateX(50px) translateY(-60px);
    }

    &-8 {
      top: 38%;
      left: 70%;
      transform: translateX(50px) translateY(-40px);
    }
  }
}

.suzuki__imgs-slide1 {
  top: 0;
  left: 0;
  animation: slideOpacity2 16s infinite;
  transform: translateX(0);
}

.hero-animation-metaverse {
  position: relative;
  width: 34.6875rem;
  aspect-ratio: 1.186 / 1;

  &__image {
    position: absolute;
    opacity: 0;
    animation: position 2s forwards;

    &--1 {
      width: 43.2%;
      z-index: 2;
      transform: translateX(200px);
    }

    &--2 {
      width: 29%;
      z-index: 1;
      top: 30.5%;
      left: 5.5%;
      transform: translateX(150px);
    }

    &--3 {
      width: 29%;
      right: 8%;
      top: 8%;
      z-index: 1;
      transform: translateX(350px);
    }

    &--4 {
      width: 15%;
      z-index: 3;
      top: 16%;
      left: -3%;
      transform: translateX(50px) translateY(-30px);
    }

    &--5 {
      width: 15%;
      z-index: 3;
      top: 6%;
      left: 11.5%;
      transform: translateX(50px) translateY(-40px);
    }

    &--6 {
      bottom: 6%;
      right: 11%;
      z-index: 4;
      transform: translateX(50px) translateY(-50px);
    }

    &--7 {
      width: 10.25%;
      z-index: 3;
      bottom: 7%;
      transform: translateX(50px) translateY(-60px);
    }

    &--8 {
      width: 13.25%;
      z-index: 3;
      bottom: 21%;
      left: 87%;
      transform: translateX(50px) translateY(-60px);
    }
  }
}

.hero-animation-iotdevelopers {
  position: relative;
  width: 35rem;
  height: 29.25rem;

  &__image {
    position: absolute;
    opacity: 0;
    animation: position 2s forwards;

    &--1 {
      z-index: 2;
      transform: translateX(200px);
    }

    &--2 {
      top: 15%;
      left: -8%;
      z-index: 1;
      transform: translateX(150px);
    }

    &--3 {
      top: 18%;
      right: -6%;
      z-index: 1;
      transform: translateX(350px);
    }

    &--4 {
      top: -8%;
      left: 58%;
      z-index: 3;
      transform: translateX(50px) translateY(-30px);
    }

    &--5 {
      top: 80%;
      left: 3%;
      z-index: 3;
      transform: translateX(50px) translateY(-60px);
    }

    &--6 {
      bottom: -2%;
      left: 12%;
      z-index: 4;
      transform: translateX(50px) translateY(-50px);
    }

    &--7 {
      bottom: 5%;
      left: 73%;
      z-index: 3;
      transform: translateX(50px) translateY(-50px);
    }

    &--8 {
      bottom: -2%;
      left: 85%;
      z-index: 3;
      transform: translateX(50px) translateY(-60px);
    }
  }
}

.hero-animation-web-design {
  position: relative;
  width: 35rem;
  height: 29.25rem;

  &__image {
    position: absolute;
    opacity: 0;
    animation: position 2s forwards;

    &--1 {
      z-index: 2;
      transform: translateX(200px);
    }

    &--2 {
      top: 15%;
      left: 9%;
      z-index: 1;
      transform: translateX(150px);
    }

    &--3 {
      top: 7%;
      right: 10%;
      z-index: 3;
      transform: translateX(350px);
    }

    &--4 {
      top: -16%;
      left: 46%;
      z-index: 3;
      transform: translateX(50px) translateY(-30px);
    }

    &--5 {
      z-index: 2;
      top: 69%;
      left: 57%;
      transform: translateX(50px) translateY(-60px);
    }
  }
}

.hero-animation-sharepoint {
  width: 100%;
  max-width: 38.5rem;
  aspect-ratio: 1.446 / 1;
  position: relative;
  top: 10%;

  @media screen and (min-width: 1400px) {
    right: -4vw;
  }

  @media screen and (min-width: 991px) and (max-width: 1300px) {
    margin-right: 1rem;
  }

  &__image {
    position: absolute;
    opacity: 0;
    animation: position 2s forwards;

    &--1 {
      z-index: 2;
      transform: translateX(200px);
      top: 0;
      right: 4.06%;
      width: 90.75%;
    }

    &--2 {
      z-index: 2;
      width: 24.03%;
      bottom: 0;
      left: 0;
      transform: translateX(350px);
    }

    &--3 {
      width: 19.81%;
      bottom: 23.47%;
      right: 9.09%;
      z-index: 3;
      transform: translateX(30px) translateY(-30px);

      img {
        transform: rotate(-4.45deg);
      }
    }

    &--4 {
      width: 19.81%;
      bottom: 28.4%;
      right: 0;
      z-index: 3;
      transform: translateX(50px) translateY(-30px);

      img {
        transform: rotate(2.48deg);
      }
    }
  }
}

.hero-animation-internalcommunicationsagency {
  width: 48rem;
  height: 26.5rem;
  position: relative;

  &__image {
    position: absolute;
    opacity: 0;
    animation: position 2s forwards;

    &--1 {
      z-index: 2;
      transform: translateX(200px);
      top: 10%;
    }
  }
}

.hero-animation-ux-design {
  width: 33.125rem;
  height: 29.25rem;
  position: relative;

  &__image {
    position: absolute;
    opacity: 0;
    animation: position 2s forwards;

    &--1 {
      left: 23.53%;
      width: 44.12%;
      z-index: 3;
      transform: translateX(200px);
    }

    &--2 {
      width: 38.82%;
      bottom: 23.5%;
      left: 0;
      z-index: 2;
      transform: translateX(150px);
    }

    &--3 {
      width: 41.18%;
      top: 19.66%;
      right: 0;
      z-index: 2;
      transform: translateX(350px);
    }

    &--4 {
      top: 29.91%;
      left: 10.78%;
      transform: translateX(30px) translateY(-30px);
    }

    &--5 {
      top: 5.56%;
      right: 16.47%;
      transform: translateX(50px) translateY(-30px);
    }

    &--6 {
      z-index: 5;
      bottom: 4.27%;
      right: 9.41%;
      transform: translateX(50px) translateY(-30px);
    }
  }
}

// .hero-animation-suzuki {
//   &__image {
//     &--1 {
//       top: 0;
//       left: 0;
//       position: absolute;
//       transform: translateX(400px);
//     }
//     &--1 {
//       top: -10%;
//       left: 2%;
//       z-index: -1;
//       transform: translateX(150px);
//       @media (max-width: $lg) {
//         position: relative !important;
//         margin-inline: 0;
//         left: 0%;
//       }
//       &__stay {
//         opacity: 0;
//         animation: position 2s;
//         animation-fill-mode: forwards;
//       }
//     }
//     &--2 {
//       top: 0%;
//       left: 70%;
//       transform: translateX(150px);
//     }
//     &--3 {
//       top: 95%;
//       left: 10%;
//       transform: translateX(100px);
//     }
//     &--4 {
//       top: 20%;
//       left: 70%;
//       transform: translateX(250px);
//     }
//     &--5 {
//       top: 55%;
//       left: 60%;
//       transform: translateX(100px);
//     }
//   }
// }

.hero-animation-suzuki {
  &--0 {
    opacity: 0;
    animation: position-down 2s;
    margin-top: rem(-28);
    opacity: 1 !important;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    transform: translateY(46px);
  }

  &__image {
    &--1 {
      animation-delay: 0.5s !important;
      animation-duration: 1s !important;
      opacity: 1 !important;
      order: -1;
      margin-right: rem(12);
      // margin-top: rem(-24);
    }

    &--1 {
      top: -10%;
      left: 2%;
      z-index: -1;
      transform: translateY(14px);

      @media (max-width: $lg) {
        position: relative !important;
        margin-inline: 0;
        left: 0%;
      }

      &__stay {
        opacity: 0;
        animation: position 2s;
        animation-fill-mode: forwards;

        @media (max-width: $lg) {
          animation: initial;
        }
      }
    }

    &--2 {
      top: rem(80);
      min-width: rem(175);
      min-height: rem(152);
      right: rem(-148);
      z-index: -1;
      position: absolute;

      // @media (max-width: 1300px) {
      //   right: rem(-92);
      // }

      // @media (max-width: 1199px) {
      //   right: rem(-148);
      // }

      .image-icon {
        display: block;
        margin-left: auto;
        right: 0;

        &--1 {
          transform: translateX(-172px);
          animation: position 1.2s 0.6s forwards, position-top 1s 3s forwards;
        }

        &--2 {
          top: 123px;
          transform: translateX(-300px);
          animation-delay: 0.7s;
          animation-duration: 1s;
        }
      }
    }

    &--3 {
      z-index: -2;
      animation-delay: 0.6s;
      top: calc(100% + 102px);
      left: 108px;
      transform: translateY(-250px);
      animation-duration: 1s;
    }

    &--4 {
      top: calc(100% - 70px);
      left: -192px;
      transform: translate(175px, -80px);
      z-index: -2;
      animation: position 1s 0.6s forwards, position-down-2 1s 3s forwards;
    }

    &--5 {
      top: calc(100% - 128px);
      left: calc(100% - 88px);
      min-width: rem(174);
      transform: translate(-100px, -100px);
      z-index: -2;
      animation: position 1s 0.6s forwards, position-down-2 1s 3s forwards;

      img {
        filter: drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.25));
      }
    }

    &--6 {
      position: absolute;
      top: calc(100% + 12px);
      left: rem(1);
      width: rem(226);

      .image-icon {
        animation-delay: 0.6s;
        z-index: -2;
        transform: translateY(-90px);

        &--3 {
          animation-duration: 1s;
        }

        &--4 {
          right: 0;
          animation: position 1.1s 0.6s forwards, position-down-2 1s 3s forwards;
        }
      }
    }

    &--7 {
      position: absolute;
      top: calc(50% + 37px);
      left: 100%;
      width: rem(100);
      min-height: rem(64);

      .image-icon {
        animation-delay: 0.6s;
        z-index: -2;
        animation-duration: 1s;
        transform: translateX(-150px);

        &--5 {
          left: rem(3);
          bottom: rem(-2);
        }

        &--6 {
          left: rem(38);
          bottom: rem(-2);
        }

        &--7 {
          left: rem(38);
          top: 0;
          transform: translateX(-250px);

          animation: position 1s 0.6s forwards, position-left-1 1s 3s forwards;
        }

        &--8 {
          left: rem(75);
          top: 0;

          animation: position 1s 0.6s forwards, position-left-2 1s 3s forwards;
        }
      }
    }
  }
}

.dma {
  margin-bottom: rem(100);
}

.mw {
  &--570 {
    @media (min-width: 991px) {
      max-width: 30rem;
    }

    @media (min-width: 1200px) {
      max-width: 35.625rem;
    }
  }
}

.hidden-tablet {
  @media (max-width: $lg) {
    display: none;
  }
}

@keyframes position {
  to {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes position-down {
  to {
    opacity: 1;
    transform: translateY(60px);
  }
}

@keyframes position-down-2 {
  to {
    transform: translateY(15px);
  }
}

@keyframes position-left-1 {
  to {
    left: rem(3);
  }
}

@keyframes position-left-2 {
  to {
    left: rem(38);
  }
}

@keyframes position-top {
  from {
    min-width: rem(174);
    top: 0;
  }

  to {
    min-width: rem(214);
    top: rem(-80);
  }
}

@keyframes slideOpacity1 {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 1;
  }

  30% {
    opacity: 0;
  }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes slideOpacity2 {
  0% {
    opacity: 0;
  }

  68.3% {
    opacity: 0;
  }

  72.6% {
    opacity: 1;
  }

  95.7% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes ScreenScrollSuzuki1 {
  25% {
    transform: translateY(0);
  }

  45% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-300px);
  }

  65% {
    transform: translateY(-300px);
  }

  70% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes ScreenScrollSuzuki2 {
  0% {
    transform: translateY(0);
  }

  17% {
    transform: translateY(0);
  }

  21.3% {
    transform: translateY(-180px);
  }

  47.3% {
    transform: translateY(-180px);
  }

  51.6% {
    transform: translateY(0);
  }

  68.3% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0);
  }
}


@keyframes screenScroll5 {
  0% {
    transform: translateY(-10%);
  }
  20% {
    transform: translateY(0);
  }
  45% {
    transform: translateY(0);
  }
  60% {
    transform: translateY(-38%);
  }
  85% {
    transform: translateY(-38%);
  }
  95% {
    transform: translateY(-10%);
  }
}